import React, {Fragment, Component, useEffect, useRef, useState, useCallback} from 'react'; //useEffect, useRef
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import {
    List, Datagrid, CreateButton,
    Pagination, Filter, TextInput, TopToolbar,
    ExportButton, ArrayField, SingleFieldList,
    ChipField, BulkExportButton, 
    ShowButton, TextField, ReferenceArrayInput,
    AutocompleteArrayInput, CheckboxGroupInput,
    useRefresh, useRecordContext, usePermissions, Button
} from 'react-admin';

import { Link } from 'react-router-dom';
import BulkCopyButton from './BulkCopyButton';
import BulkInactivateButton from './BulkInactivateButton';
import BulkRunButton from './BulkRunButton';
import ViewLicensingDrawer from './ViewLicensing'
import RunJob from './RunButton'
import CheckForUpdates from './CheckForUpdatesButton'
import InactivateJob from './InactivateButton'
import CopyJob from './CopyButton';
import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import Moment from 'moment-timezone';
import JobView from '../../components/JobStatusPanel'
import JobRowStyle from '../../theme/JobRowStyle';
import FileUploadView from '../../components/FileUploadPanel'
import * as Config from '../../Config'
import EditIcon from '@mui/icons-material/Edit';

const exporter = records => {
    const forExport = records.map(item => {
        const {createdBy,modifiedBy,offerCount,jobProgressTrackerId,autoRun,countOffersUpdatedAndActive,countOfferUserModifiedAndActive,countOffersUpdated,countOffersUserModified,countOffersInvalid,countOffersDuplicate,countOffersPending,countOffersNeedAttention,countOffersSystemCreated,countOffersPushed,licenseeType,modifiedById,deals, ...licensingForExport } = item;
        licensingForExport.description = item.description
        licensingForExport.catalogs = item.catalogs.map(catalog => {return catalog.titleWithId}).join(' | ');
        licensingForExport.medias = item.medias.map(media => {return media.name}).join(' | ');
        licensingForExport.territories = item.territories.map(territory => {return territory.name }).join(' | ');
        licensingForExport.licenseeTypeList = item.licenseeTypeList.join(' | ');
        licensingForExport.licenseeTypeList = item.licenseeTypeList.join(' | ');
        //licensingForExport.deals = item.deals.map(deal => {return deal.titleWithId }).join(' | ');
        licensingForExport.id = item.id
        licensingForExport.status = item.status
        licensingForExport.createdBy = item.createdBy.fullName
        licensingForExport.modifiedBy = item.modifiedBy.fullName
        return licensingForExport;
    });
    jsonExport(forExport, {
        headers: ['id', 'description','catalogs', 'medias', 'territories', 'licenseeTypeList', 'startDate', 'endDate', 'status', 'lastRun', 'completedAt', 'createdBy', 'createdAt', 'modifiedBy',  'modifiedAt'], // order fields in the export
        rename: ['Id', 'Description','Catalogs', 'Medias', 'Territories', 'Licensee Type', 'Start Date', 'End Date', 'Status', 'Lastest Job', 'Completed', 'Created By', 'Created', 'Updated By', 'Modified']
    }, (err, csv) => {
        downloadCSV("\ufeff"+csv, 'licensing'); // download as 'posts.csv` file
    });
};

const FileUploadButton = ({ record = {} }) => <FileUploadView type={'LicensingJob'} title={'Upload'} />
const LicensingActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  permissions
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <ShowFileUploadButtonAdmin />
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ShowCreateButtonAdmin/>
        <span style={{ width: '15px' }}>&nbsp;</span>
        <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
        />
    </TopToolbar>
);
const ShowFileUploadButtonAdmin = props => {
  return <FileUploadButton {...props}/>  
};
const ShowCreateButtonAdmin = props => {  
  return <Fragment><CreateButton {...props}/></Fragment>
};


export class LicensingFilterComponent extends Component {
  render() {
    const validateSpecialCharacters = (name) => {
        const specialChars = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/
        if (specialChars.test(name)) {
            return 'Input may not contain [`!@#$%^&*()_+-={};\':"\\|,.<>/?~]';
        }
        return undefined;
    }
    return <Filter {...this.props}>
        <TextInput type="number" label="Licensing Job Id" source="jId" alwaysOn resettable validate={validateSpecialCharacters} />
        <TextInput type="number" label="Catalog Id" source="catalogId" alwaysOn resettable validate={validateSpecialCharacters} />
        <TextInput label="Catalog Name" source="catalogName" alwaysOn resettable validate={validateSpecialCharacters} />
        <ReferenceArrayInput allowEmpty={false} label="Users" reference="users" source="users" alwaysOn filter={{"dataType": "licensingJob"}} sort={{ field: 'fullName', order: 'ASC' }} perPage={200}>
          <AutocompleteArrayInput optionText="fullName" resettable  />
        </ReferenceArrayInput>
        <ReferenceArrayInput allowEmpty={false} label="Status" reference="licensing/jobStatuses" source="statuses">
          <AutocompleteArrayInput optionText="name" resettable  />
        </ReferenceArrayInput>
        <ReferenceArrayInput allowEmpty={false} label="Media" reference="licensing/getMediaTerritoryList/media" source="mediaIds">
          <AutocompleteArrayInput optionText="label" resettable  />
        </ReferenceArrayInput>
        <ReferenceArrayInput allowEmpty={false} label="Territory" reference="licensing/getMediaTerritoryList/territory" source="territoryIds">
          <AutocompleteArrayInput optionText="label" resettable  />
        </ReferenceArrayInput>
        <CheckboxGroupInput label="Licensee Type" source="licenseeTypeList" choices={[
              { _id: 'GLOBAL', label: 'Global (Amazon, Apple, Google, Microsoft, Vubiquity)' },
              { _id: 'LOCAL', label: 'Local (all other Licensees)' },                           
          ]} optionValue="_id" optionText="label" />                    
    </Filter>
  }
}

export const LicensingFilter = props => (
  <LicensingFilterComponent {...props}  />
);

const BulkActionButtons = props => {
  const { permissions } = usePermissions();
  return <Fragment>
        <BulkCopyButton label="Copy" {...props} />
        <BulkRunButton label="Run" {...props} />
        { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) &&  <BulkInactivateButton label="Inactivate" {...props} /> }
        <BulkExportButton {...props} />
    </Fragment>
    
}

var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';
const CreatedDateFormatWithTime = ({ record = {} }) => <span>{record.createdAt ? Moment(Moment(record.createdAt).local().format(localFormat)).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z') : ""}</span>;
const ModifiedDateFormatWithTime = ({ record = {} }) => <span>{record.modifiedAt ? Moment(Moment(record.modifiedAt).local().format(localFormat)).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z'): ""}</span>;
const CompletedDateFormatWithTime = ({ record = {} }) => <span>{record.completedAt ? Moment(Moment(record.completedAt).local().format(localFormat)).tz(Moment.tz.guess()).format('DD-MMM-YYYY, h:mm:ss A z'): ""}</span>;

const StartDateFormat = ({ record = {} }) => <span>{record.startDate ? Moment(Moment(record.startDate).local().format(localFormat)).format('DD-MMM-YYYY') : ""}</span>;
const EndDateFormat = ({ record = {} }) => <span>{record.endDate ? Moment(Moment(record.endDate).local().format(localFormat)).format('DD-MMM-YYYY') : ""}</span>;

const LicensingViewButton = ({ record = {} }) => <ViewLicensingDrawer id={record.id} />
const RunJobButton = ({ record = {} }) => <RunJob disabled={record.status === "RUNNING" || record.status === "RETRYING"} label='Run' id={record.id}  />
const CheckForUpdatesButton = ({ record = {} }) => <CheckForUpdates disabled={record.status === "RUNNING"} label='Updates' id={record.id}  />
const InactivateJobButton = ({ record = {} }) => {
  const { permissions } = usePermissions();
  return <Fragment>
          { permissions && permissions?.split(',').includes(Config.ROLE_TAP_ADMIN) && <InactivateJob label='Inactivate' id={record.id}  offerCount={record.offerCount}/> }
  </Fragment>
};
//const InactivateJobButton = ({ record = {} }) => <InactivateJob label='Inactivate' id={record.id} />
const CopyJobButton = ({ record = {} }) => {  
  return <CopyJob label='Copy' id={record.id} />
};
//const CopyJobButton = ({ record = {} }) => <CopyJob label='Copy' id={record.id} />
const ShowEditButtonAdmin = ({ record = {} }) => {
  return <Link to={{pathname: '/licensing/list/' + record.id}}><Button label="Edit"><EditIcon/></Button></Link>  
};
const OfferTableButton = ({ record = {} }) =><ShowButton disabled={record.status === "RUNNING" || record.status === "RETRYING"} style={{ width: '150px' }} to={{ pathname: "/licensing/offerTable/"+record.id }} replace id={record.id} label={record.status === "RUNNING" ? 'Pending' : `Offers (` + record.offerCount + `)`}/>

const OfferStatusCounts = ({ record = {} }) =><span><span style={{ color: record.countOffersPending > 0 ? 'green' : 'black' }}>Pending ({record.countOffersPending})</span> / <span style={{ color: record.countOffersPushed > 0 ? 'green' : 'black' }}>Pushed ({record.countOffersPushed})<br/></span><span style={{ color: record.countOffersSystemCreated > 0 ? 'red' : 'black' }}>System Created ({record.countOffersSystemCreated})<br/></span><span style={{ color: (record.countOffersUpdated + record.countOffersUserModified)> 0 ? 'red' : 'black' }}>Updated/Modified ({record.countOffersUpdated + record.countOffersUserModified})</span><br/><span style={{ color: (record.countOffersUpdatedAndActive + record.countOfferUserModifiedAndActive)> 0 ? 'red' : 'black' }}>Active Updated/Modified ({record.countOffersUpdatedAndActive + record.countOfferUserModifiedAndActive})</span><br/><span style={{ color: record.countOffersDuplicate > 0 ? 'red' : 'black' }}>Duplicate ({record.countOffersDuplicate})</span><br/><span style={{ color: record.countOffersNeedAttention > 0 ? 'red' : 'black' }}>Needs Attention ({record.countOffersNeedAttention})</span></span>


const PaginationOptions = props => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />

const JobViewButton = ({ record = {} }) => <JobView title={record.status === "QUEUED_UPDATED" ? 'QUEUED FOR UPDATES' : record.status === "QUEUED_UPDATED_USER_INITIATED" ? 'QUEUED FOR UPDATES USER INITIATED' : record.status} id={record.jobProgressTrackerId} />

function useRecursiveTimeout(callback, delay) {
      const savedCallback = useRef(callback)

      useEffect(() => {
        savedCallback.current = callback
      }, [callback])
 

      useEffect(() => {        
        let id
        function tick() {                              
          if (document.getElementById('JobRefresh') && 
          document.getElementById('JobRefresh').className &&
          document.getElementById('JobRefresh').className === 'Refresh') {                 
            const ret = savedCallback.current()
            if (ret instanceof Promise) {
              ret.then(() => {
                if (delay !== null) {
                  id = setTimeout(tick, delay)
                }
              })
            } else {
              if (delay !== null) {
                id = setTimeout(tick, delay)
              }
            }
          } else {
            console.log("Not refreshing ")        
            id = setTimeout(tick, delay)
          }          
        }
        if (delay !== null) {
          id = setTimeout(tick, delay)
          return () => id && clearTimeout(id)
        }
      }, [delay])
}

export const LicensingListRefresh = ({ state, permissions, ...props }) => {                    
  const refresh = useRefresh()    
  useRecursiveTimeout(() => refresh(), 15000)
  return (
    <LicensingList {...props} />
  )
}

const TagsField = () => {
  const record = useRecordContext();
  return (  
      <div>    
        {record && record.licenseeTypeList && record.licenseeTypeList.length > 0 && record.licenseeTypeList.map(item => (
          <div class="MuiChip-root RaChipField-chip-129 MuiChip-sizeSmall">
            <span class="MuiChip-label MuiChip-labelSmall" key={item}>{item}</span>
          </div>
        ))}
      </div>
  )
};

class LicensingList extends React.Component {  
  render() {        
    return <Fragment>
    <List id="LicensingList"  empty={false} perPage={localStorage.getItem('licensing/list/perPage') ? localStorage.getItem('licensing/list/perPage') : 5} pagination={<PaginationOptions />} exporter={exporter} title="Licensing Jobs" bulkActionButtons={<BulkActionButtons  {...this.props} />} actions={<LicensingActions {...this.props}/>} filters={<LicensingFilter />} {...this.props}  sort={{ field: 'id', order: 'DESC' }} >
          <Datagrid rowStyle={JobRowStyle}>
              <LicensingViewButton source="view" />
              <ShowEditButtonAdmin />              
              <TextField label="Id" source="id" />
              <TextField label="Description" source="description" />
              <LicensingCatalogList label="Catalogs" />
              <ArrayField sortable={false} label="Medias" source="medias">
                <SingleFieldList linkType={false}>
                  <ChipField source="name"  size="small" />
                </SingleFieldList>
              </ArrayField>
              <LicensingTerritoryList label="Territories" />
              <TagsField label="Licensee Type"/>
              

              <StartDateFormat source="startDate" label="Start Date" />
              <EndDateFormat source="endDate" label="End Date" />
              <RunJobButton source="Run" {...this.props} sortable={false}/>
              <CheckForUpdatesButton source="Check For Updates" {...this.props} sortable={false}/>                                                        
              <JobViewButton label="Status" />                            
              <OfferTableButton source="offers" sortable={false}  />
              <OfferStatusCounts sortable={false} label="Offer Status" />
              <TextField label="Latest Job" source="lastRun" />
              <CompletedDateFormatWithTime source="completedAt" label="Completed" />
              <TextField source="createdBy.fullName" label="Created By" />
              <CreatedDateFormatWithTime source="createdAt" label="Created" />
              <TextField source="modifiedBy.fullName" label="Updated By" />
              <ModifiedDateFormatWithTime source="modifiedAt" label="Modified" />
              <CopyJobButton {...this.props}/>
              <InactivateJobButton {...this.props}/>
          </Datagrid>
      </List>      
    </Fragment>
  }
};
//<TextField source="id" label="Job"/>

export const LicensingCatalogList = () => {
    const record = useRecordContext();
    const [showAll, setShowAll] = useState(false);

    let [displayedStates, setDisplayedStates] = useState(() => {
        const storedShowAll = localStorage.getItem('showAllCatalogs');
        let stateArray = [];
        if (storedShowAll) {
            try {
                const parsedValue = JSON.parse(storedShowAll);
                stateArray = Array.isArray(parsedValue) ? parsedValue : [];
            } catch (e) {
                console.error('Error parsing JSON: ', e);
            }
        }

        return stateArray;
    });

    const getShowAllState = useCallback((recordId) => {
        const storedState= Array.isArray(displayedStates) ? displayedStates.find(state => {
            return state.id === recordId
        }): [];
        return storedState ? storedState.state : false;
    }, [displayedStates])

    const [limit, setLimit] = useState(5);    
    var displayedCatalogs = [];
    if (showAll && record)
      displayedCatalogs = record.catalogs 
    else if (record && record.catalogs && record.catalogs.length > 0)
      displayedCatalogs = record.catalogs.slice(0,limit);

    useEffect(() => {
        setShowAll(getShowAllState(record.id));
    },[getShowAllState, record.id]);

    const showMore = (recordId) => {
        let prevStates = [];
        if(displayedStates && displayedStates.length > 0) {
            const existsId = displayedStates.some(obj => obj.id === recordId);
            if(existsId) {
                displayedStates = displayedStates.filter(obj => obj.id !== recordId);
                prevStates = displayedStates
                const obj = {}
                obj.id = recordId;
                obj.state = true;
                prevStates.push(obj);
            } else {
                prevStates = displayedStates;
                const obj = {}
                obj.id = recordId;
                obj.state = true;
                prevStates.push(obj);
            }
        } else {
            const obj = {}
            obj.id = recordId;
            obj.state = true;
            prevStates.push(obj);
        }

        localStorage.setItem('showAllCatalogs', JSON.stringify(prevStates))
        setDisplayedStates(prevStates);
        setShowAll(getShowAllState(recordId));
        setLimit(displayedStates.length);
    };

    const showLess = (recordId) => {
        let prevStates = [];
        prevStates = displayedStates.filter(obj => obj.id !== recordId);

        const obj = {}
        obj.id = recordId;
        obj.state = false;
        prevStates.push(obj);

       localStorage.setItem('showAllCatalogs', JSON.stringify(prevStates))
        setDisplayedStates(prevStates)
        setShowAll(getShowAllState(recordId));
        setLimit(5);
    };

    return (
        <>
            <div className="catalogsLicensingContainer">
                <>
                    {displayedCatalogs.map(catalog => (
                        <span className="catalogLicensing">{catalog.titleWithId.replace(/\s+/g, ' ')}</span>
                    ))}
                </>
                <div className="showAllCatalogsLicensingContainer">
                    {!showAll && record.catalogs && record.catalogs.length > limit && (
                        <div onClick={() => showMore(record.id)} className="showAllCatalogsLicensing">
                            <ExpandMoreRoundedIcon/>
                        </div>
                    )}
                </div>
                <div className="showLimitCatalogsLicensingContainer">
                    {showAll && record.catalogs && record.catalogs.length > limit && (
                        <div onClick={() => showLess(record.id)} className="showLimitCatalogsLicensing">
                            <ExpandLessRoundedIcon/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export const LicensingTerritoryList = () => {
    const record = useRecordContext();
    const [showAll, setShowAll] = useState(false);

    let [displayedStates, setDisplayedStates] = useState(() => {
        const storedShowAll = localStorage.getItem('showAllTerritories');
        let stateArray = [];
        if (storedShowAll) {
            try {
                const parsedValue = JSON.parse(storedShowAll);
                stateArray = Array.isArray(parsedValue) ? parsedValue : [];
            } catch (e) {
                console.error('Error parsing JSON: ', e);
            }
        }

        return stateArray;
    });

    const getShowAllState = useCallback((recordId) => {
        const storedState= Array.isArray(displayedStates) ? displayedStates.find(state => {
            return state.id === recordId
        }): [];
        return storedState ? storedState.state : false;
    }, [displayedStates])

    const [limit, setLimit] = useState(5);
    var displayedTerritories = [];
    if (showAll && record)
      displayedTerritories = record.territories 
    else if (record && record.territories && record.territories.length > 0)
      displayedTerritories = record.territories.slice(0,limit);

    useEffect(() => {
        setShowAll(getShowAllState(record.id));
    },[getShowAllState, record.id]);

    const showMore = (recordId) => {
        let prevStates = [];
        if(displayedStates && displayedStates.length > 0) {
            const existsId = displayedStates.some(obj => obj.id === recordId);
            if(existsId) {
                displayedStates = displayedStates.filter(obj => obj.id !== recordId);
                prevStates = displayedStates
                const obj = {}
                obj.id = recordId;
                obj.state = true;
                prevStates.push(obj);
            } else {
                prevStates = displayedStates;
                const obj = {}
                obj.id = recordId;
                obj.state = true;
                prevStates.push(obj);
            }
        } else {
            const obj = {}
            obj.id = recordId;
            obj.state = true;
            prevStates.push(obj);
        }

        localStorage.setItem('showAllTerritories', JSON.stringify(prevStates))
        setDisplayedStates(prevStates);
        setShowAll(getShowAllState(recordId));
        setLimit(displayedStates.length);
    };

    const showLess = (recordId) => {
        let prevStates = [];
        prevStates = displayedStates.filter(obj => obj.id !== recordId);

        const obj = {}
        obj.id = recordId;
        obj.state = false;
        prevStates.push(obj);

        localStorage.setItem('showAllTerritories', JSON.stringify(prevStates))
        setDisplayedStates(prevStates)
        setShowAll(getShowAllState(recordId));
        setLimit(5);
    };

    return (
        <>
            <div className="territoriesLicensingContainer">
                <>
                    {displayedTerritories.map(territory => (
                        <span className="territoryLicensing">{territory.name.replace(/\s+/g, ' ')}</span>
                    ))}
                </>
                <div className="showAllTerritoriesLicensingContainer">
                    {!showAll && record.territories && record.territories.length > limit && (
                        <div onClick={() => showMore(record.id)} className="showAllTerritoriesLicensing">
                            <ExpandMoreRoundedIcon/>
                        </div>
                    )}
                </div>
                <div className="showLimitTerritoriesLicensingContainer">
                    {showAll && record.territories && record.territories.length > limit && (
                        <div onClick={() => showLess(record.id)} className="showLimitTerritoriesLicensing">
                            <ExpandLessRoundedIcon/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};